<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import UserService from '@/services/user'
import { VEmojiPicker } from 'v-emoji-picker';


export default {
  page: {
    title: "Notificações",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, VEmojiPicker },
  data() {
    return {
      title: "Notificações",
      notification: {
        title: '',
        message: '',
        user: 'all',
      },
      showEmojis: false,
    };
  },
  methods: {
    sendNotification() {
      if (!this.notification.title || !this.notification.message) {
        return this.$toast.warning('Preencha todos os campos');
      }

      this.$bvModal.hide('modal-confirm');
      
      let loader = this.$loading.show();

      UserService.sendNotification(this.notification).then(() => {
        loader.hide();

        this.$toast.open('Mensagem enviada com sucesso');

        this.notification.title = '';
        this.notification.message = '';
      }).catch((err) => {

        this.$toast.error(err);
        loader.hide();
      });
    },
    selectEmoji(emoji) {
      this.notification.message += emoji.data;
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <!-- <h4 class="card-title">Notificação Push</h4>
        <p class="card-title-desc">A mensagem será enviada para todos os dispositivos com o aplicativo instalado</p> -->
        <div class="row">
          <div class="col-lg-6">
            <form>
              <div class="row">
                <div class="col-12">
                  <b-alert show variant="warning">Importante: A mensagem será encaminhada para TODOS os celulares com o aplicativo instalado</b-alert>
                  <div class="form-group">
                    <label for="title">Título da Mensagem</label>
                    <input id="title" name="title" type="text" class="form-control" :maxlength="50" v-model="notification.title"/>
                    <div v-if="notification.title" class="text-left  ">
                      <p
                        class="badge position-absolute"
                        :class="{ 'badge-success': notification.title.length !== 50,
                        'badge-danger': notification.title.length === 50 }"
                      >{{ notification.title.length }} / 50</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="title">Mensagem</label>
                    <textarea id="message" class="form-control" rows="6" v-model="notification.message" :maxlength="150" placeholder="Informe aqui o conteúdo da mensagem"></textarea>
                    <div v-if="notification.message" class="text-left  ">
                      <p
                        class="badge position-absolute"
                        :class="{ 'badge-success': notification.message.length !== 150,
                        'badge-danger': notification.message.length === 150 }"
                      >{{ notification.message.length }} / 150</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-2 align-items-center">
                <div class="col-sm-6">
                  <button type="button" class="btn btn-primary btn-rounded mb-2" :disabled="!notification.title || !notification.message" @click="$bvModal.show('modal-confirm')">
                    <i class="mdi mdi-check mr-1"></i> Enviar Mensagem
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="col">
            <b-form-checkbox v-model="showEmojis" switch class="mb-1">
              <label>Visualizar Emojis</label>
            </b-form-checkbox>
            
             <VEmojiPicker @select="selectEmoji" :showSearch="false" :emojiSize="26" :emojisByRow="8" v-if="showEmojis"/>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-confirm"
      title="Confirma Envio da Mensagem?"
      title-class="font-18"
      centered
    >
      <p>A mensagem será enviada imediatamente para todos os usuários do aplicativo.</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-confirm')" class="mr-3">Cancelar</b-btn>
        <b-btn variant="primary" @click="sendNotification">Pode Enviar</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>
